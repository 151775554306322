import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/website/src/components/DocsLayout.tsx";
import TrackLevelEasing from "../../../content/examples/track-level-easing.mdx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Easing and interpolating refer to two types of functions that React Ensemble uses to turn your track regions into fluid animations.`}</p>
    <p>{`React Ensemble relies on the awesome libraries `}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-ease"
      }}><strong parentName="a">{`d3-ease`}</strong></a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-interpolate"
      }}><strong parentName="a">{`d3-interpolate`}</strong></a>{` to perform these actions, but you can also extend the behavior with your own.`}</p>
    <h2 {...{
      "id": "easing"
    }}>{`Easing`}</h2>
    <blockquote>
      <p parentName="blockquote">{`"Easing is a method of distorting time to control apparent motion in animation." — d3`}</p>
    </blockquote>
    <p>{`Usually, we do not want time to be exactly linear in our animations.
An element that slides in at a constant speed is much less exciting than one that bounces, springs, or boomerangs in.
We can control this effect with an `}<em parentName="p">{`easing function`}</em>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/d3/d3-ease"
      }}><strong parentName="a">{`d3-ease`}</strong></a>{` includes a number of interesting easing functions.
For convenience, React Ensemble exports this package at `}<inlineCode parentName="p">{`Lib.d3Ease`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Lib } from "react-ensemble";
const { d3Ease } = Lib;
`}</code></pre>
    <h3 {...{
      "id": "setting-the-default-ease-function"
    }}>{`Setting the Default Ease Function`}</h3>
    <p>{`By default, every track region is animated with `}<inlineCode parentName="p">{`d3Ease.easeCubic`}</inlineCode>{`. To change this setting, set the `}<inlineCode parentName="p">{`easing`}</inlineCode>{` prop in your `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{`.`}</p>

    <TrackLevelEasing mdxType="TrackLevelEasing" />
    <h3 {...{
      "id": "region-specific-easing"
    }}>{`Region-Specific Easing`}</h3>
    <p>{`You can override the ease function for a particular region by setting that region's `}<inlineCode parentName="p">{`easing`}</inlineCode>{` field.`}</p>
    <p>{`If that region is a group, the new ease function you've specified will be the default for all of its child regions.`}</p>
    <h3 {...{
      "id": "writing-your-own-ease-function"
    }}>{`Writing Your Own Ease Function`}</h3>
    <p>{`If `}<a parentName="p" {...{
        "href": "https://github.com/d3/d3-ease"
      }}><strong parentName="a">{`d3-ease`}</strong></a>{` doesn't have the ease function you need, you can write your own.`}</p>
    <p>{`An ease function must match the following type definition:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`(progress: number) => number;
`}</code></pre>
    <p>{`It's important to note that ease functions work with `}<strong parentName="p">{`normalized times`}</strong>{`, which are a decimal between `}<inlineCode parentName="p">{`[0,1]`}</inlineCode>{`.
This number represents the percentage of the region that has elapsed.`}</p>
    <p>{`For example, normalized time at the start of a region is `}<inlineCode parentName="p">{`0`}</inlineCode>{`, halfway through is `}<inlineCode parentName="p">{`0.5`}</inlineCode>{`, and at the end is `}<inlineCode parentName="p">{`1`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "interpolating"
    }}>{`Interpolating`}</h2>
    <p>{`Interpolation functions blend gradually between two values.
In track regions with state properties, React Ensemble uses an interpolation function (or `}<strong parentName="p">{`interpolator`}</strong>{`) to generate every intermediate value for that property during the region.`}</p>
    <p>{`For example, interpolating between the values `}<inlineCode parentName="p">{`10`}</inlineCode>{` and `}<inlineCode parentName="p">{`20`}</inlineCode>{` would return `}<inlineCode parentName="p">{`10`}</inlineCode>{` at the beginning of the region, `}<inlineCode parentName="p">{`15`}</inlineCode>{` halfway through, `}<inlineCode parentName="p">{`17.5`}</inlineCode>{` three-quarters of the way through, and `}<inlineCode parentName="p">{`20`}</inlineCode>{` at the end.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/d3/d3-interpolate"
      }}><strong parentName="a">{`d3-interpolate`}</strong></a>{` contains a powerful interpolation function that can handle many types of values.
For convenience, React Ensemble exports this package at `}<inlineCode parentName="p">{`Lib.d3Interpolate`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Lib } from "react-ensemble";
const { d3Interpolate } = Lib;
`}</code></pre>
    <h3 {...{
      "id": "setting-the-default-interpolation-function"
    }}>{`Setting the Default Interpolation Function`}</h3>
    <p>{`By default, every track region is interpolated with `}<inlineCode parentName="p">{`d3Interpolate.interpolate`}</inlineCode>{`. To change this setting, set the `}<inlineCode parentName="p">{`interp`}</inlineCode>{` prop in your `}<inlineCode parentName="p">{`Timeline`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "region-specific-interpolation"
    }}>{`Region-Specific Interpolation`}</h3>
    <p>{`You can override the interpolator for a particular region by setting that region's `}<inlineCode parentName="p">{`interp`}</inlineCode>{` field.`}</p>
    <p>{`If that region is a group, the new interpolator you've specified will be the default for all of its child regions.`}</p>
    <h3 {...{
      "id": "writing-your-own-interpolation-function"
    }}>{`Writing Your Own Interpolation Function`}</h3>
    <p><inlineCode parentName="p">{`Lib.d3Interpolate`}</inlineCode>{` can handle many types of values, including number, strings, colors, arrays, and objects. If you need to write your own, however, your interpolator must match the following type definition:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`<T>(start: T, end: T) => (progress: number) => T;
`}</code></pre>
    <p>{`An interpolator takes `}<inlineCode parentName="p">{`start`}</inlineCode>{` and `}<inlineCode parentName="p">{`end`}</inlineCode>{` values and builds another function that will return some intermediate value when given a number between `}<inlineCode parentName="p">{`[0,1]`}</inlineCode>{`.
Most of the time, `}<inlineCode parentName="p">{`progress=0`}</inlineCode>{` would return `}<inlineCode parentName="p">{`start`}</inlineCode>{`, and `}<inlineCode parentName="p">{`progress=1`}</inlineCode>{` would return `}<inlineCode parentName="p">{`end`}</inlineCode>{`.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`<T>`}</inlineCode>{` syntax is just a generic type that means the `}<inlineCode parentName="p">{`start`}</inlineCode>{`, `}<inlineCode parentName="p">{`end`}</inlineCode>{`, and return value must all be the same type.`}</p>
    <h2 {...{
      "id": "further-reading"
    }}>{`Further Reading`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/d3/d3-ease"
        }}>{`d3-ease Docs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/d3/d3-interpolate"
        }}>{`d3-interpolate Docs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/lib"
        }}>{`Lib API`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api/types#trackregion"
        }}>{`TrackRegion API`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      